import React, { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/joy/CircularProgress';
import {API_GetAuthToken, API_URL} from '../components/API/v1_speechTo3d_api';
import {useAuth} from '../hook/useAuth'


// ----------------------------- Function -----------------------------
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {'Manuel Weid'}
      {' - '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Validation = (username, password) => {
  let message = '';

  if(username.length == 0)
    message += 'username is to short; ';

  if(password.length == 0)
    message += 'password is to short; ';

  return message;
};



const SignIn = () => {

  // define component states
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { signIn } = useAuth();

  const SignIn = async (e) => {
    e.preventDefault();

    // get server address
    console.log(`### Server-URL: ${API_URL} ###`);

    // user input validation
    const validationMessage = Validation(username, password);
    setErrorMessage(validationMessage);


    if (!validationMessage) {

      let accessToken = await API_GetAuthToken(username, password)

      // handle response
      if(accessToken){

        // reset error message
        setErrorMessage("");
        let output_text = `### Access-Token: ${accessToken} ###`;

        // output token
        console.log('#'.repeat(output_text.length));
        console.log(output_text);
        console.log('#'.repeat(output_text.length));

        // save token in context
        signIn(accessToken);
      }
      else{

        // output error message
        console.error('Failed to get access token');
        setErrorMessage("Can not find this combination of username and password");
      }
    }
  };


  const defaultTheme = createTheme();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={SignIn} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}

            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {errorMessage && (
              <Box color="error.main" component="div">
                {errorMessage}
              </Box>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Login'}
            </Button>

          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}


export default SignIn;