import { useContext } from 'react';
import { APIContext } from '../context/APIContext'

export const useAPI = () => {
    const context = useContext(APIContext);

    if(!context) {
        throw Error('useAPI must be used inside an APIContextProvider');
    }

    return context;
  };