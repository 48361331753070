import React, { useRef, useState, useEffect } from 'react';
import { OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import RotatingCube from "./RotatingCube"
import ModelObj from "./ModelObj"


const ModelViewer = ({modelData}) => {

  const controlsRef = useRef();

  // Reset zoom when modelData changes
  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.reset();
      console.log("Reset OrbitControls");
    }
  }, [controlsRef, modelData]);

  return (
    <div style={{ height: '70vh' }}>
      <Canvas>
        <OrbitControls ref={controlsRef}/>
        <ambientLight />
        {modelData ? <ModelObj modelData={modelData} /> : <RotatingCube />}
      </Canvas>
    </div>
  );
}

export default ModelViewer;
