import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const SnackbarAlert = ({ open, onClose, severity, message }) => (
  <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
    <Alert onClose={onClose} severity={severity}>
      {message}
    </Alert>
  </Snackbar>
);

const useAlert = () => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [alertMessage, setAlertMessage] = useState('');

  const showAlert = (severity, message) => {
    setAlertSeverity(severity);
    setAlertMessage(message);
    setAlertOpen(true);
  };

  const closeAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
    setAlertSeverity('success');
    setAlertMessage('');
  };

  const AlertComponent = () => (
    <SnackbarAlert
      open={alertOpen}
      onClose={closeAlert}
      severity={alertSeverity}
      message={alertMessage}
    />
  );

  return { showAlert, AlertComponent };
};

export default useAlert;
