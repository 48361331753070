import {createContext, useState} from "react";

// create new context object
export const APIContext = createContext();

// create provider to pass context to all the children
export const APIContextProvider = ({ children }) => {
    const [prompt, setPrompt]                 = useState("");
    const [modelType, setModelType]           = useState("");
    const [modelData, setModelData]           = useState("");
    const [processId, setProcessId]           = useState("");

    const [imageStatus, setImageStatus]       = useState(false);
    const [modelStatus, setModelStatus]       = useState(false);
    const [checkStatus, setCheckStatus]       = useState(false);
    const [downloadStatus, setDownloadStatus] = useState(false);

    return (
      <APIContext.Provider value={
        {
          modelData, setModelData,
          prompt, setPrompt,
          modelType, setModelType,
          processId, setProcessId,
          imageStatus, setImageStatus,
          modelStatus, setModelStatus,
          checkStatus, setCheckStatus,
          downloadStatus, setDownloadStatus
        }
        }>
        {children}
      </APIContext.Provider>
    );
};