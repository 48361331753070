import React from 'react';
import { Typography } from '@mui/material';



const Description = ({text}) => {

  if(text != ""){
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: "1.5em", paddingTop: "2.5em"}}>
            <Typography component="div" style={{fontSize: "1.75em"}}>
                Prompt: {text}
            </Typography>
        </div>
        )
  }

};

export default Description;


