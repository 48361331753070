import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';

const ProgressVisualization = ({ steps, activeStep }) => {

    const containerStyle = {
        margin: '2em auto',
        maxWidth: '600px',
    };

return (
    <div style={containerStyle}>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step, index) => (
                <Step key={index} completed={step.completed}>
                    <StepLabel>{step.name}</StepLabel>
                </Step>
            ))}
        </Stepper>
    </div>
);
};

export default ProgressVisualization;
