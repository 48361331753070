
import './App.css';
import { useAuth } from './hook/useAuth';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import { APIContextProvider } from './context/APIContext';

function App() {
  const {isSignedIn} = useAuth();

  return (
    <div>
      {isSignedIn ? (
        <APIContextProvider>
          <Dashboard />
        </APIContextProvider>
      ) : (
        <SignIn />
      )}
    </div>
  );

}

export default App;
