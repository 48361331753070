// ---------------------------------------------------------------------------------------------------------------
// API-URL
// ---------------------------------------------------------------------------------------------------------------
export const API_URL = 'https://text-2-3d.mixedrealitylab.de/api/';


// ---------------------------------------------------------------------------------------------------------------
// Get access to authentication token
// ---------------------------------------------------------------------------------------------------------------
export async function API_GetAuthToken(username, password){



    // define data request data
    const data = new URLSearchParams();
    data.append('username', username);
    data.append('password', password);
    data.append('grant_type', 'password');

    // url path
    const AUTH_URL = "/auth/token"

    // type of data
    const HEADERS = {'Content-Type': 'application/x-www-form-urlencoded'}

    // send post message to api
    const response = await fetch(API_URL + AUTH_URL, {
      method: 'POST',
      headers: HEADERS,
      body: data
    });

    // handle response
    if(response.ok){
        let res = await response.json();
        return res.access_token;
    }
    else{
        return null;
    }
}

// ---------------------------------------------------------------------------------------------------------------
// Send prompt to API
// ---------------------------------------------------------------------------------------------------------------
export async function API_SendPrompt(token, prompt, modelName){
  // define data request data
  const data = new URLSearchParams();
  data.append('prompt', prompt);

  // url path
  const PROMPT_URL = "/model/" + modelName + "/upload_prompt"

  // type of data
  const HEADERS = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  // send post message to api
  const response = await fetch(API_URL + PROMPT_URL, {
    method: 'POST',
    headers: HEADERS,
    body: data
  });

  // handle response
  if(response.ok){
      let res = await response.json();
      return res.pid;
  }
  else{
      return null;
  }
}

// ---------------------------------------------------------------------------------------------------------------
// Send command to create image based on prompt
// ---------------------------------------------------------------------------------------------------------------
export async function API_CreateImage(token, pid, modelName){
  // define data request data
  const data = new URLSearchParams();
  data.append('pid', pid);

  // url path
  const PROMPT_URL = "/model/" + modelName + "/generate_image"

  // type of data
  const HEADERS = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  // send post message to api
  const response = await fetch(API_URL + PROMPT_URL, {
    method: 'POST',
    headers: HEADERS,
    body: data
  });

  // handle response
  if(response.ok){
      let res = await response.json();
      return true;
  }
  else{
      return null;
  }
}

// ---------------------------------------------------------------------------------------------------------------
// Send command to create model based on image
// ---------------------------------------------------------------------------------------------------------------
export async function API_CreateModel(token, pid, modelName){
  // define data request data
  const data = new URLSearchParams();
  data.append('pid', pid);

  // url path
  const PROMPT_URL = "/model/" + modelName + "/generate_model"

  // type of data
  const HEADERS = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  // send post message to api
  const response = await fetch(API_URL + PROMPT_URL, {
    method: 'POST',
    headers: HEADERS,
    body: data
  });

  // handle response
  if(response.ok){
      let res = await response.json();
      return true;
  }
  else{
      return null;
  }
}

// ---------------------------------------------------------------------------------------------------------------
// Check status of model creating
// ---------------------------------------------------------------------------------------------------------------
export async function API_CheckStatus(token, pid, modelName){
  // define data request data
  const data = new URLSearchParams();
  data.append('pid', pid);

  // url path
  const PROMPT_URL = "/model/" + modelName + "/status"

  // type of data
  const HEADERS = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  // send post message to api
  const response = await fetch(API_URL + PROMPT_URL, {
    method: 'POST',
    headers: HEADERS,
    body: data
  });

  // handle response
  if(response.ok){
      let res = await response.json();
      return res.status;
  }
  else{
      return null;
  }
}

// ---------------------------------------------------------------------------------------------------------------
// Download finished model
// ---------------------------------------------------------------------------------------------------------------
export async function API_GetModel(token, pid, modelName){
  // define data request data
  const data = new URLSearchParams();
  data.append('pid', pid);

  // url path
  const PROMPT_URL = "/model/" + modelName + "/download_model"

  // type of data
  const HEADERS = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/x-www-form-urlencoded'
  }

  // send post message to api
  const response = await fetch(API_URL + PROMPT_URL, {
    method: 'POST',
    headers: HEADERS,
    body: data
  });

  // handle response
  if(response.ok){
      let res = await response.json();
      return res.content;
  }
  else{
      return null;
  }
}