import {createContext, useState} from "react";

// create new context object
export const AuthContext = createContext();

// create provider to pass context to all the children
export const AuthContextProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(null);
    const [isSignedIn, setIsSignedIn] = useState(false);

    const signIn = (token) => {
      setAccessToken(token);

      if(typeof token == "string" && token.length != 0){
        setIsSignedIn(true);
      }
    };

    const signOut = () => {
      setAccessToken(null);
      setIsSignedIn(false);
    };

    return (
      <AuthContext.Provider value={{accessToken, signIn, signOut, isSignedIn}}>
        {children}
      </AuthContext.Provider>
    );
};