// TopBar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Button, TextField } from '@mui/material';

const PromptInput = ({ onSendClick, onTextChange, sendText, textFieldText }) => {
    const [promptText, setPromptText] = useState('');

    const handleChange = (event) => {
        const value = event.target.value;
        setPromptText(value);
        onTextChange(value);
    };

return (
    <div style={{ display: 'flex', width: '100%' }}>
        <TextField
            id="outlined-textarea"
            label={textFieldText}
            placeholder={textFieldText}
            multiline
            variant="outlined"
            onChange={handleChange}
            style={{ flex: 1, marginRight: '8px'}}
        />

        <Button variant="contained" onClick={() => onSendClick(promptText)}>
            {sendText}
        </Button>
    </div>
  );
};

export default PromptInput;
