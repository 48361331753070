import React from 'react';
import { useLoader } from '@react-three/fiber';

import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

const ModelObj = ({ modelData }) => {

    if(modelData){
        let loader = new OBJLoader();
        let obj = loader.parse(modelData);

        return (
            <primitive object={obj} />
        );
    }
    else{
        return <primitive />
    }

};

export default ModelObj;
