import React, { useState, useEffect } from 'react';
import { MenuItem, FormControl, Select, InputLabel } from '@mui/material';

const DropdownMenu = ({ options, caption, onSelect }) => {
  const [selectedOption, setSelectedOption] = useState(options[0] || '');

  useEffect(() => {
    onSelect(selectedOption);
  }, [onSelect, selectedOption]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-helper-label">{caption}</InputLabel>
      <Select
        label={caption}
        labelId="dropdown-label"
        value={selectedOption}
        onChange={handleChange}
        displayEmpty
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownMenu;
