import React, { useRef, useMemo } from 'react';
import { useFrame  } from 'react-three-fiber';


const RotatingCube = () => {
    const cubeRef = useRef();

    const DefaultCube = () => {
      return (
        <mesh ref={cubeRef}>
          <boxGeometry args={[2, 2, 2]} />
          <meshStandardMaterial color={"#1976D2"} />
        </mesh>
      );
    };

    useFrame((state, delta) => {
      cubeRef.current.rotation.y += delta;
    });


    return <DefaultCube />;
};

export default RotatingCube;
