import React, { useState, useEffect } from 'react';
import {useAPI} from '../hook/useAPI'
import TopBar from '../components/Dashboard_Elements/TopBar'
import DropdownMenu  from '../components/Dashboard_Elements/DropdownMenu'
import PromptInput  from '../components/Dashboard_Elements/PromptInput'
import ModelViewer  from '../components/Dashboard_Elements/Model/ModelViewer'
import Loader  from '../components/Dashboard_Elements/Loader'
import Description  from '../components/Dashboard_Elements/Description'
import ProgressVisualization  from '../components/Dashboard_Elements/ProgressVisualization'
import {API_SendPrompt, API_CreateImage, API_CreateModel, API_CheckStatus, API_GetModel} from '../components/API/v1_speechTo3d_api';
import {useAuth} from '../hook/useAuth'
import useAlert  from '../hook/useAlert'


const Dashboard = () => {
  // ----------------------------------------------------------------------------
  // default data for visualization
  const [steps, setSteps] = useState([
    { name: 'Upload prompt',  completed: false },
    { name: 'Generate image', completed: false },
    { name: 'Create model',   completed: false },
    { name: 'Finished model', completed: false },
    { name: 'Download model', completed: false },
  ]);
  const modelDropdownCaption = "3D model"
  const [modelOptions, setModelOptions] = useState(["demo", "one2345", "wonder3D"]);
  // ----------------------------------------------------------------------------

  // Provider -------------------------------------------------------------------
  const { modelData, setModelData,
          prompt, setPrompt,
          modelType, setModelType,
          processId, setProcessId,
          imageStatus, setImageStatus,
          modelStatus, setModelStatus,
          checkStatus, setCheckStatus,
          downloadStatus, setDownloadStatus} = useAPI();

  const { accessToken, isSignedIn } = useAuth();
  // ----------------------------------------------------------------------------

  // Components -----------------------------------------------------------------
  const {showAlert, AlertComponent }    = useAlert();           // Notification
  const [activeStep, setActiveStep]     = useState(0);          // ProgressVisualization
  const [activeLoader, setActiveLoader] = useState(false);
  // ----------------------------------------------------------------------------



  // click on create model button
  const setEnterPrompt = (text) => {
    // validate length
    if(text.length == 0) {
      showAlert("error", "Prompt length must be greater then zero");
      return;
    }

    resetStates();
    resetProgress();

    console.log(`Define prompt: ${text}`);
    setPrompt(text);
  }

  // Effects --------------------------------------------------------------------

  // Effects - API_SendPrompt ---------------------------------------------------
  const pid = async () => {
    const pid = await API_SendPrompt(accessToken, prompt, modelType);
    return pid;
  }

  useEffect(() => {

    if (prompt !== "") {

      // upload prompt and get process id
      pid().then((result) => {

        if(result){

          // set process id
          setProcessId(result);
          console.log(`Define pid: ${result}`);

          // next progress step
          nextProgress(1);
        }
        else{
          showAlert("error", "Error by uploading prompt");
        }

      });
    }
  }, [prompt]);


  // Effects - API_SendPrompt ---------------------------------------------------
  const imageGen = async () => {
    const result = await API_CreateImage(accessToken, processId, modelType);
    return result;
  }

  useEffect(() => {

    if (processId !== "") {
      // upload prompt and get process id
      imageGen().then((result) => {
        if(result){
          setImageStatus(true);

          // next progress step
          nextProgress(2);
        }
        else{
          showAlert("error", "Error by creating image for prompt");
        }
      });
    }
  }, [processId]);


  // Effects - API_SendPrompt ---------------------------------------------------
  const modelGen = async () => {
    const result = await API_CreateModel(accessToken, processId, modelType);
    return result;
  }

  useEffect(() => {

    if (imageStatus === true) {
      // upload prompt and get process id
      modelGen().then((result) => {
        if(result){
          setModelStatus(true);

          // next progress step
          nextProgress(3);
          setActiveLoader(true);
        }
        else{
          showAlert("error", "Error by creating model for prompt");
        }
      });
    }
  }, [imageStatus]);


  // Effects - API_SendPrompt ---------------------------------------------------
  const checkModel = async () => {
    const result = await API_CheckStatus(accessToken, processId, modelType);
    return result;
  }

  useEffect(() => {

    if (modelStatus === true) {

      // check status of model
      const intervalId  = setInterval(() => {

        checkModel().then((result) => {
          if(result){

            // check status value
            if(result  === "RUNNING"){
              showAlert("info", "Model is generating, please wait");
            }
            else{
              setCheckStatus(true);
              setImageStatus(false);

              // next progress step
              nextProgress(4);
              clearInterval(intervalId);
            }
          }
          else{
            showAlert("error", "Error by checking model status");
          }
        });
      }, 8000);
    }
  }, [modelStatus]);


  // Effects - API_GetModel ---------------------------------------------------
  const downloadModel = async () => {
    const result = await API_GetModel(accessToken, processId, modelType);
    return result;
  }

  useEffect(() => {
    if (checkStatus === true) {

      downloadModel().then((result) => {
        if(result){

          if(result !== ''){
            setModelData(result);

            // next progress step
            nextProgress(5);

            setDownloadStatus(true);
            setActiveLoader(false);
          }
          else{
            showAlert("error", "Error downloaded model was empty");
          }


        }
        else{
          showAlert("error", "Error by creating image for prompt");
        }
      });
    }
  }, [checkStatus]);

  // ----------------------------------------------------------------------------






  // ProgressVisualization ------------------------------------------------------
  const nextProgress=(nextIndex) => {
    // copy all steps
    const updatedSteps = [...steps];

    // validate index
    // lower boarder
    if(nextIndex < 0){
      setActiveStep(0);
      updatedSteps[0].completed = true;
      setSteps(updatedSteps);
      return;
    }
    // upper boarder
    if(nextIndex >= updatedSteps.length){
      setActiveStep(updatedSteps.length-1);
      updatedSteps[updatedSteps.length-1].completed = true;
      setSteps(updatedSteps);
      return;
    }

    // update values for index
    for (let i = 0; i < nextIndex; i++) {
      updatedSteps[i] = { ...updatedSteps[i], completed: true };
    }

    setSteps(updatedSteps);
    setActiveStep(nextIndex);
  }

  const resetProgress = () => {
    // copy all steps
    const updatedSteps = [...steps];

    // update values pre index
    for (let i = 0; i < updatedSteps.length; i++) {
      updatedSteps[i] = { ...updatedSteps[i], completed: false };
    }

    // reset process
    setSteps(updatedSteps);
    setActiveStep(0);
  }

  const resetStates = () => {
    // reset states
    setPrompt("");
    setModelData("");
    setProcessId("");

    setImageStatus(false);
    setModelStatus(false);
    setCheckStatus(false);
    setDownloadStatus(false);
    setActiveLoader(false);
  }


  // ----------------------------------------------------------------------------
//<div class="animation">
      //  {isLoading && <Loading />}
      //</div>
  // DOM ------------------------------------------------------------------------
  return (
    <div>
      <div class="notifications">
        <AlertComponent />
      </div>
      <TopBar>
        <DropdownMenu options={modelOptions} caption={modelDropdownCaption} onSelect={(model) => {setModelType(model);}}/>
        <PromptInput
          onSendClick={setEnterPrompt}
          onTextChange={(text) => {  }}
          sendText={"Create model"}
          textFieldText={"Object-Prompt"}
        />
      </TopBar>
      <div>
        <Loader active={activeLoader}/>
      </div>
      <ProgressVisualization steps={steps} activeStep={activeStep} style={{display: "flex", marginTop: "20em"}}/>
      <Description text={prompt}/>
      <ModelViewer modelData={modelData}/>
    </div>
  );
};
  // ----------------------------------------------------------------------------
//modelData={modelData}
export default Dashboard;
