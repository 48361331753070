// TopBar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Button, TextField } from '@mui/material';
import './TopBar.css';

const TopBar = ({ children }) => {


  return (
    <AppBar position="static" style={{paddingTop: '0.5em'}}>
      <Toolbar>
        {children}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
