import React from 'react';
import { LinearProgress } from '@mui/material';



const Loader = ({active}) => {

  if(active == true){
    return (
      <LinearProgress color="inherit"
      sx={{
        color: (theme) => (theme.palette.mode === 'light' ? '#808080' : '#808080'),
      }}
      thickness={4}
     />)
  }

};

export default Loader;
